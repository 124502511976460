// withBalance.js (HOC)
import React, { useEffect, useState } from "react";
import ARC200Service from "../services/ARC200Service";
import { makeStdLib } from "../utils/reach";

const stdlib = makeStdLib();
const fc = stdlib.formatCurrency;

const withBalance = (WrappedComponent) => {
  return ({ appId, backendId, address, ...props }) => {
    const [balance, setBalance] = useState(null);

    useEffect(() => {
      // Fetch the balance and update the state
      ARC200Service.balanceOf(appId, address, backendId)
        .then(fc)
        .then((balance) => {
          setBalance(balance);
        });
    }, [appId, backendId, address]);

    // If the balance is still null (fetching in progress), you can show a loader or placeholder
    //if (balance === null) {
    //  return <div>Loading...</div>;
    //}

    // Pass the balance as a prop to the WrappedComponent
    return (
      <WrappedComponent
        {...props}
        loading={balance === null}
        balance={balance}
      />
    );
  };
};

export default withBalance;
