import { Container } from "@mui/material";
import React from "react";

const UserDashboard = () => {
  return (
    <Container>
      <h1>User Dashboard</h1>
    </Container>
  );
};

export default UserDashboard;
