import React from "react";
import AssetCard from "./AssetCard";

const tokens = [
  {
    appId: 265385886,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "NYC Apartment Token",
      symbol: "NYCAPT",
      decimals: 18,
      totalSupply: "1000000000000000000000000",
    },
  },
  {
    appId: 265385949,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "SF Condo Token",
      symbol: "SFCT",
      decimals: 18,
      totalSupply: "500000000000000000000000",
    },
  },
  {
    appId: 265386018,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "London Townhouse Token",
      symbol: "LTT",
      decimals: 18,
      totalSupply: "800000000000000000000000",
    },
  },
  {
    appId: 265386094,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "Tokyo Penthouse Token",
      symbol: "TPT",
      decimals: 18,
      totalSupply: "300000000000000000000000",
    },
  },
  {
    appId: 265386141,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "Miami Villa Token",
      symbol: "MVT",
      decimals: 18,
      totalSupply: "600000000000000000000000",
    },
  },
  {
    appId: 265386252,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "Sydney Harbor Apartment Token",
      symbol: "SHAT",
      decimals: 18,
      totalSupply: "200000000000000000000000",
    },
  },
  {
    appId: 265386340,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "Paris Chateau Token",
      symbol: "PCT",
      decimals: 18,
      totalSupply: "700000000000000000000000",
    },
  },
  {
    appId: 265386416,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "Dubai Luxury Suite Token",
      symbol: "DLST",
      decimals: 18,
      totalSupply: "400000000000000000000000",
    },
  },
  {
    appId: 265386517,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "Vancouver Mountain Retreat Token",
      symbol: "VMRT",
      decimals: 18,
      totalSupply: "250000000000000000000000",
    },
  },
  {
    appId: 265386577,
    zeroAddress: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ",
    enableZeroAddressBurn: false,
    meta: {
      name: "Cape Town Oceanfront Token",
      symbol: "CTOT",
      decimals: 18,
      totalSupply: "900000000000000000000000",
    },
  },
];

const CardGrid = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        gap: "20px",
      }}
    >
      {tokens.slice(0, 1).map((el) => (
        <AssetCard
          key={el.appId}
          appId={el.appId}
          title={el.meta.name}
          content="Denver, CO"
          imageUrl={"https://picsum.photos/200/300"}
          assetType="Multifamily"
          annualReturn="15.1%"
          cashOnCash="6.7%"
          equityMultiple="1.9x"
          projectValue="32M"
          minInvestment="$25"
        />
      ))}
    </div>
  );
};

export default CardGrid;
