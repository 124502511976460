import SignInSide from "../../components/SignInSide";
import { useSelector } from "react-redux";
import Transactions from "../../components/Transactions";

const Page = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  return isLoggedIn ? <Transactions /> : <SignInSide />;
};

export default Page;
