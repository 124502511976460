import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ChecklistIcon from "@mui/icons-material/Checklist";
const Sidebar = () => {
  const navigate = useNavigate();
  const options = [
    /*
    {
      text: "General",
      icon: null,
      to: "/settings",
    },
    */
    {
      text: "Profile",
      icon: <AccountBoxIcon />,
      to: "/settings/profile",
    },
    {
      text: "Verification",
      icon: <ChecklistIcon />,
      to: "/settings/verify",
    },
  ];
  return (
    <List>
      {options.map((el) => (
        <ListItem button onClick={() => navigate(el.to)}>
          <ListItemIcon>{el.icon}</ListItemIcon>
          <ListItemText primary={el.text} />
        </ListItem>
      ))}
      {/* Add more items here */}
      {/*<Divider />*/}
    </List>
  );
};

export default Sidebar;
