import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// ES Modules, e.g. transpiling with Babel
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import * as AWS from "aws-sdk/global";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { login } from "../../actions/authActions";
import Copyright from "../PoweredBy";

const AWSRegion = "us-east-1";

const cognitoConfig = {
  demo2: [
    "us-east-1_ozU2lm0TM",
    "7cp69su09uuf46spr6sr7ep72d",
    "us-east-1:76a9861f-69a4-4e6a-befb-20c1a9300879",
  ],
};

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const qs = search
    .slice(1)
    .split("&")
    .reduce((acc, val) => {
      const [key, value] = val.split("=");
      return Object.assign({ [key]: value }, acc);
    }, {});
  const [state, setState] = React.useState({});
  const [selectedOption, setSelectedOption] = React.useState("demo2");
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const handleLogin = React.useCallback(
    (e) => {
      e.preventDefault();
      // Perform login logic here, e.g., making an API call

      const [UserPoolId, ClientId, IdentityPoolId] =
        cognitoConfig[selectedOption];

      var authenticationData = {
        Username: email, //username,
        Password: password,
      };
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      var poolData = {
        UserPoolId, // Your user pool id here
        ClientId, // Your client id here
      };
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      var userData = {
        Username: email, //username,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          console.log({ result });
          var accessToken = result.getAccessToken().getJwtToken();

          //POTENTIAL: Region needs to be set if not already set previously elsewhere.
          AWS.config.region = AWSRegion;

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId, // your identity pool id here
            Logins: {
              // Change the key below according to the specific region your user pool is in.
              [`cognito-idp.${AWSRegion}.amazonaws.com/${UserPoolId}`]: result
                .getIdToken()
                .getJwtToken(),
            },
          });
          //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
          AWS.config.credentials.refresh((error) => {
            if (error) {
              console.error(error);
            } else {
              // Instantiate aws sdk service objects now that the credentials have been updated.
              // example: var s3 = new AWS.S3();
              console.log("Successfully logged!");
            }
          });
          dispatch(login(cognitoUser));
          const navigateTo = "redirect_to" in qs ? qs.redirect_to : "/";
          if (selectedOption === "accinv") {
            navigate(navigateTo);
          } else {
            navigate(navigateTo);
          }
        },
        newPasswordRequired: (userAttr) => {
          setState({
            isFirstLogin: true,
            user: cognitoUser,
            userAttr: userAttr,
          });
        },
        onFailure: function (err) {
          alert(err.message || JSON.stringify(err));
        },
      });
    },
    [selectedOption, username, password]
  );

  /*
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  */

  const changePassword = (newPassword) => {
    const cognitoUser = state.user;
    const userAttr = state.userAttr;
    console.log({ userAttr });
    // non-writable attributes if any
    ["email", "email_verified"].forEach((el) => {
      if (el in userAttr) {
        delete userAttr[el];
      }
    });
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttr, {
      onSuccess: (result) => {
        alert("login");
      },
      onFailure: (err) => {
        console.log({ err });
      },
    });
  };

  const handleChangePasswordSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const nPassword = data.get("n_password");
    const cnPassword = data.get("cn_password");
    if (nPassword !== cnPassword) {
      alert("Passwords don't match. Try again.");
      return;
    }
    const re =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+=\[\]{}|':,.?\/`~"<>;:\\-])[A-Za-z\d!@#$%^&*()_+=\[\]{}|':,.?\/`~"<>;:\\-]{8,}$/;
    if (!nPassword.match(re)) {
      alert(
        "Password does not fullfill password strength requirements. Password must contain at least 8 characters, 1 uppercase letter (A-Z), 1 lowercase letter(a-z), 1 number (0-9) and 1 special character (! @ # $ % ^ & * ( ) _ + - = [ ] { } | ' : , . ? / ` ~ < > ; :)"
      );
      return;
    }
    console.log({
      n_password: data.get("n_password"),
      cn_password: data.get("cn_password"),
    });
    changePassword(nPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {!state.isFirstLogin ? (
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleLogin}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/*
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
            */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  {/*<Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
              </Grid>*/}
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Set Password
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleChangePasswordSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="n_password"
                  label="New Password"
                  type="password"
                  id="n_password"
                  //autoComplete="current-password"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="cn_password"
                  label="Confirm New Password"
                  type="password"
                  id="cn_password"
                  //autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Update Password
                </Button>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
