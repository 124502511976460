import React from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  disclaimerContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  disclaimerTitle: {
    fontWeight: 'bold',
  },
  disclaimerText: {
    color: '#555',
  },
}));

const DisclaimersList = () => {
  const classes = useStyles();

  const disclaimers = [
    {
      title: 'Structure',
      content: 'This is the disclaimer content',
    },
    {
      title: 'Risk of Investment',
      content: 'This is the disclaimer content',
    },
    {
      title: 'Fees and Conflicts',
      content: 'This is the disclaimer content',
    },
    {
      title: 'Not financial advice',
      content: 'This is the disclaimer content',
    },
    {
      title: 'No approval, opinion, or representation, or warranty by Smart Block Island',
      content: 'This is the disclaimer content',
    },
    


     
    // Add more disclaimers as needed
  ];

  return (
    <div className={classes.disclaimerContainer}>
      {disclaimers.map((disclaimer, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.disclaimerTitle}>{disclaimer.title}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.disclaimerText}>{disclaimer.content}</div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default DisclaimersList;
