import SignInSide from "../../components/SignInSide";
import { useSelector } from "react-redux";
import Marketplace from "../../components/Marketplace";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import AssetCard from "../../components/Marketplace/AssetCard";
import InvestmentForm from "../../components/InvestmentForm";
import PhotoGallery from "../../components/PhotoGallery";
import DocumentTable from "../../components/DocumentTable";
import DisclaimersList from "../../components/DisclaimerList";

const Page = () => {
  const { appId } = useParams();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  return isLoggedIn ? (
    <Container
      maxWidth="lg"
      sx={{
        marginTop: "3em",
        //display: "flex",
        //alignItems: "center",
        //justifyContent: "center",
        height: "100vh",
        minWidth: "600",
      }}
    >
      <Stack direction="row" gap={3}>
        <AssetCard
          appId={appId}
          title={"NYC Apartment Token"}
          content="New York, NY"
          imageUrl={"https://picsum.photos/200/300"}
          assetType="Multifamily"
          annualReturn="15.1%"
          cashOnCash="6.7%"
          equityMultiple="1.9x"
          projectValue="32M"
          minInvestment="$25"
        />
        <Stack gap={1} sx={{ flexGrow: 1 }}>
          <Typography variant="h6">NYC Appartment</Typography>
          <Typography variant="body2">
            <strong>Annual Return:</strong> 15.1%
          </Typography>
          <Typography variant="body2">
            <strong>Cash on Cash:</strong> 6.7%
          </Typography>
          <Typography variant="body2">
            <strong>Equity Multiple:</strong> 1.9x
          </Typography>
          <Typography variant="body2">
            <strong>Project Value:</strong> 32M
          </Typography>
          <Typography variant="body2">
            <strong>Min. Investment:</strong> $25
          </Typography>
          <Divider />
          <InvestmentForm />
        </Stack>
      </Stack>
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Stack gap={1}>
              <Typography variant="h5">Summary</Typography>
              <Divider />
              <Typography variant="p">
                SM Capital is adding to its portfolio with the purchase of an
                NYC apartment property comprising 112 units in the vibrant city
                of New York. This acquisition marks SM Capital's 12th successful
                investment in the New York area. They will take over the
                existing loan with a favorable fixed rate of 3.32% and an
                interest-only period until November 2026. The loan is set to
                mature in November 2031. The property's robust in-place cash
                flow, combined with the attractive financing terms, presents an
                appealing opportunity for investors, ensuring steady cash flow
                and security.
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Stack gap={1}>
              <Typography variant="h5">About the Operator</Typography>
              <Divider />
              <Typography variant="p">
                Introducing SB Capital, a prominent and visionary real estate
                operator making waves in the industry with their bold and
                innovative approach. Founded by Samuel Brooks, an enterprising
                entrepreneur with a passion for real estate, SB Capital has
                rapidly emerged as a trailblazer in the competitive market.
                <br />
                At the core of SB Capital's philosophy is a commitment to
                delivering excellence. With a team of seasoned experts, Samuel
                has meticulously cultivated a reputation for exceptional
                customer service, attention to detail, and a keen understanding
                of market dynamics. This expertise allows SB Capital to identify
                lucrative investment opportunities that others might overlook,
                consistently delivering impressive returns to their diverse
                portfolio of clients.
                <br />
                SB Capital's diverse portfolio showcases their versatility and
                adaptability. From high-end luxury properties in prestigious
                neighborhoods to affordable housing projects that uplift
                communities, Samuel and his team fearlessly tackle a wide range
                of ventures. Each project is approached with meticulous research
                and strategic planning, ensuring that every investment decision
                aligns with the company's vision of creating lasting value.
                <br />
                One of SB Capital's distinguishing traits is their
                forward-thinking approach to sustainable development. Samuel is
                a firm believer in balancing business success with environmental
                responsibility. Emphasizing eco-friendly building practices,
                energy-efficient technologies, and community-centered designs,
                SB Capital seeks to create real estate projects that not only
                enhance the lives of residents but also contribute positively to
                the environment and local communities.
                <br />
                As an industry leader, Samuel Brooks is not content with merely
                achieving personal success. He actively promotes knowledge
                sharing and mentorship within the real estate community,
                nurturing the next generation of aspiring real estate operators.
                Samuel's dedication to giving back extends beyond business
                endeavors, as SB Capital actively supports various charitable
                initiatives and social causes, striving to make a meaningful
                impact on society as a whole.
                <br />
                With a clear vision, a strong sense of purpose, and a track
                record of success, SB Capital continues to redefine the real
                estate landscape, leaving an indelible mark on the industry and
                inspiring others to think big and make a difference in the world
                of real estate.
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Stack gap={1}>
              <Typography variant="h5">Business Plan</Typography>
              <Divider />
              <Typography variant="p">
                SM Capital is adding to its portfolio with the purchase of an
                NYC apartment property comprising 112 units in the vibrant city
                of New York. This acquisition marks SM Capital's 12th successful
                investment in the New York area. They will take over the
                existing loan with a favorable fixed rate of 3.32% and an
                interest-only period until November 2026. The loan is set to
                mature in November 2031. The property's robust in-place cash
                flow, combined with the attractive financing terms, presents an
                appealing opportunity for investors, ensuring steady cash flow
                and security.
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Stack gap={1}>
              <Typography variant="h5">Photo Gallery</Typography>
              <Divider />
              <PhotoGallery />
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Stack gap={1}>
              <Typography variant="h5">Location</Typography>
              <Divider />
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24567.649147585937!2d-105.03033167812043!3d39.67320110366502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c7fc6ff941957%3A0xd0256d90e34438e6!2sKennerly%20Construction%20Corporation.!5e0!3m2!1sen!2sus!4v1690859314346!5m2!1sen!2sus"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Stack gap={1}>
              <Typography variant="h5">Investor Documents</Typography>
              <Divider />
              <DocumentTable
                documents={[
                  {
                    name: "Private Placement Memorandum",
                    date: "2023-07-31",
                    url: "https://example.com/investment_summary.pdf",
                  },
                  {
                    name: "Manager Agreement",
                    date: "2023-07-31",
                    url: "https://example.com/risk_factors.pdf",
                  },
                  {
                    name: "Offering Summary",
                    date: "2023-07-31",
                    url: "https://example.com/manager_agreement.pdf",
                  },
                  {
                    name: "Risk Factors",
                    date: "2023-07-31",
                    url: "https://example.com/manager_agreement.pdf",
                  },
                  {
                    name: "Market Analysis",
                    date: "2023-07-31",
                    url: "https://example.com/manager_agreement.pdf",
                  },
                ]}
              />
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Paper elevation={3} sx={{ p: 2, mb: 5, borderRadius: 5 }}>
            <Stack gap={1}>
              <Typography variant="h5">Disclaimers</Typography>
              <Divider />
              <DisclaimersList />
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <SignInSide />
  );
};

export default Page;
