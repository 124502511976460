import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import UserProfileTable from "../../components/UserProfileTable";

const UserProfile = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);
  return (
    <Container>
      <h1>User Profile</h1>
      <UserProfileTable />
    </Container>
  );
};

export default UserProfile;
