import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import USDCIcon from "../../assets/usdc.svg";
import { InputAdornment, Skeleton } from "@mui/material";
import withBalance from "../../hocs/withARC200Balance";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: "2rem",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontWeight: 900,
    fontSize: "0.875rem",
    marginRight: theme.spacing(2),
  },
  balance: {
    fontSize: "1.25rem",
    textAlign: "left",
  },
  loadingBalance: {
    flexGrow: 1
  },
  paperContainer: {
    padding: theme.spacing(2),
    backgroundColor: "grey",
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    flexGrow: 1,
    display: "flex", // Added to make it a flex container
    alignItems: "center", // Vertically center the content
  },
  dollarSign: {
    marginRight: theme.spacing(1), // Add spacing between $ sign and balance
  },
}));

const USDCBalance = ({ balance, loading }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={2} className={classes.iconContainer}>
        {/* Icon */}
        <img src={USDCIcon} className={classes.icon} color="primary" />
      </Grid>
      <Grid item xs={3}>
        {/* Label */}
        <Typography className={classes.label}>USD</Typography>
        <Typography className={classes.label}>Balance</Typography>
      </Grid>
      <Grid
        item
        xs={7}
        container
        justifyContent="flex-end"
        sx={{ flexGrow: 1 }}
      >
        {/* USDC Balance in Paper */}
        <Paper elevation={3} className={classes.paperContainer}>
          <InputAdornment position="start" className={classes.dollarSign}>
            $
          </InputAdornment>
          {!loading ? (
            <Typography className={classes.balance}>
              {Number(balance).toLocaleString()}
            </Typography>
          ) : (
            <Skeleton className={classes.loadingBalance} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withBalance(USDCBalance);
