import SignInSide from "../../components/SignInSide";
import { useSelector } from "react-redux";
import Marketplace from "../../components/Marketplace";

const Page = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  return isLoggedIn ? <Marketplace /> : <SignInSide />;
};

export default Page;
