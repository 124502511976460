import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";

const withTableData = (WrappedComponent) => {
  return function WithTableData(props) {
    const user = useSelector((state) => state.user);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    useEffect(() => {
      if (!user) return;
      const poolData = {
        UserPoolId: user.pool.userPoolId,
        ClientId: user.pool.clientId,
      };
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      const userData = {
        Username: user.username, // Replace with the user's username
        Pool: userPool,
      };
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: user.signInUserSession.refreshToken.token,
      });
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        console.log({ session });
        if (err) {
          console.error("Error refreshing token:", err);
          return;
        }
        const refreshedAccessToken = session.getAccessToken().getJwtToken();
        console.log("Refreshed JWT access token:", refreshedAccessToken);
        // ---
        const AWS = require("aws-sdk");
        AWS.config.update({
          region: "us-east-1",
        });
        const cognito = new AWS.CognitoIdentityServiceProvider();
        const params = {
          AccessToken: refreshedAccessToken,
        };
        cognito.getUser(params, (err, data) => {
          if (err) {
            console.error(err);
            return;
          }
          const userAttributes = data.UserAttributes;
          // Process the user attributes
          const profileData = {};
          userAttributes.forEach((attribute) => {
            console.log(
              `Attribute Name: ${attribute.Name}, Value: ${attribute.Value}`
            );
            profileData[attribute.Name] = attribute.Value;
          });
          setData(profileData);
          const columns = [
            { field: "name", label: "Name" },
            { field: "address", label: "Address" },
            { field: "birthdate", label: "Birthdate" },
            { field: "email", label: "Email" },
            { field: "email_verified", label: "Email Verified" },
            { field: "phone_number", label: "Phone Number" },
            { field: "phone_number_verified", label: "Phone Number Verified" },
            // Add more columns as needed
          ];
          setColumns(columns);
        });
      });
    }, [user]);
    return <WrappedComponent data={data} columns={columns} {...props} />;
  };
};

export default withTableData;
