import SignInSide from "../../components/SignInSide";
import { useSelector } from "react-redux";
import Documents from "../../components/Documents";

const Page = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  return isLoggedIn ? <Documents /> : <SignInSide />;
};

export default Page;
