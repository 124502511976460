import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Skeleton,
  Stack,
} from "@mui/material";

import * as icoBackend from "../../backend/ico/index.ico.mjs";
import { useWallet } from "@txnlab/use-wallet";
import { makeStdLib } from "../../utils/reach";
import { zeroAddress } from "../../utils/algorand";
import { fromSome } from "../../utils/common";

import LinearProgress from "../LinearProgressWithLabel";

const stdlib = makeStdLib();
const pc = stdlib.parseCurrency;
const bn = stdlib.bigNumberify;
const fc = stdlib.formatCurrency;

const icoCtcInfo = 266874037;

const InvestmentForm = ({ onSubmit }) => {
  const [state, setState] = useState();
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [commitment, setCommitment] = useState();
  const { activeAccount } = useWallet();

  useEffect(() => {
    if (!activeAccount) return;
    if (commitment) return;
    (async () => {
      const acc = await stdlib.connectAccount({ addr: zeroAddress });
      const ctc = acc.contract(icoBackend, icoCtcInfo);
      const commitment = fc(
        fromSome(await ctc.v.commitment(activeAccount.address), bn(0))
      );
      setCommitment(commitment);
    })();
  }, [activeAccount]);

  useEffect(() => {
    if (state) return;
    (async () => {
      const acc = await stdlib.connectAccount({ addr: zeroAddress });
      const ctc = acc.contract(icoBackend, icoCtcInfo);
      const state = fromSome(await ctc.v.state(), {});
      setState(state);
    })();
  }, [state]);

  console.log({ state });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const parsedAmount = parseFloat(investmentAmount);
    if (isNaN(parsedAmount)) {
      alert("Please enter a valid investment amount.");
    } else {
      //onSubmit(parsedAmount);
      const acc = await stdlib.connectAccount({ addr: activeAccount.address });
      const ctc = acc.contract(icoBackend, icoCtcInfo);
      await ctc.a.Trader.makeCommitment(pc(parsedAmount));
      setState(null);
      setCommitment(parsedAmount);
    }
  };

  return (
    <Stack gap={1}>
      <Typography variant="body2">
        <strong>Total Commitment:</strong>
      </Typography>
      <LinearProgress
        value={(Number(fc(state?.totalCommitment || bn(0))) / 32_000_000) * 100}
        label={
          !state ? (
            <Skeleton sx={{ display: "inline" }} variant="text" width={10} />
          ) : (
            `$${Number(fc(state?.totalCommitment || bn(0))).toLocaleString()}`
          )
        }
      />
      <Typography variant="body2">
        <strong>Your Commitment:</strong> $
        {!commitment || !state ? (
          <Skeleton sx={{ display: "inline" }} variant="text" width={10} />
        ) : (
          `${Number(commitment).toLocaleString()} (${Number(
            (Number(commitment) / Number(fc(state?.totalCommitment))) * 100
          ).toFixed(2)}%)`
        )}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mt={2}>
          <TextField
            size="small"
            id="investmentAmount"
            label="Enter Investment Amount"
            variant="outlined"
            type="number"
            fullWidth
            value={investmentAmount}
            onChange={(e) => setInvestmentAmount(e.target.value)}
            inputProps={{
              min: 25,
              step: 25,
            }}
          />
        </Box>
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </form>
    </Stack>
  );
};

export default InvestmentForm;
