import React, { useCallback, useState } from "react";
import { TextField, Button, Grid, Hidden } from "@mui/material";

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import { toast } from "react-toastify";

// cognitoConfig
// - UserPoolId
// - ClientId
// - IdentityPoolId
const cognitoConfig = {
  accinv: [
    "us-east-1_jACxkXaPO",
    "2hp2i2bohkv6co9vglrtpg57f2",
    "us-east-1:d4ba6cbf-255b-4bc3-aa2b-57f4c2f70a9c",
  ],
  member: [
    "us-east-1_synT6l4pN",
    "50ulqrn72cpcrhqcmum0qgc2t",
    "us-east-1:36a97af0-6654-4c6b-9e63-2303442df06a",
  ],
};

const ResetPasswordForm = ({ username, selectedOption }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSendCode = useCallback(
    (e) => {
      e.preventDefault();
      if (!selectedOption || !username) return;
      // ---
      const [UserPoolId, ClientId, IdentityPoolId] =
        cognitoConfig[selectedOption];
      var poolData = {
        UserPoolId, // Your user pool id here
        ClientId, // Your client id here
      };
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      var userData = {
        Username: username,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      // ---
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
          console.log("CodeDeliveryData from forgotPassword: " + data);
        },
        onFailure: function (err) {
          alert(err.message || JSON.stringify(err));
        },
        //Optional automatic callback
        inputVerificationCode: function (data) {
          console.log("Code sent to: " + data);
        },
      });
      // ---
    },
    [selectedOption, username]
  );

  const handleForgotPassword = useCallback(
    (e) => {
      e.preventDefault();
      if (!selectedOption || !username) return;
      // ---
      const [UserPoolId, ClientId, IdentityPoolId] =
        cognitoConfig[selectedOption];
      var poolData = {
        UserPoolId, // Your user pool id here
        ClientId, // Your client id here
      };
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      var userData = {
        Username: username,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      console.log({ verificationCode, newPassword });
      // ---
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          console.log("Password confirmed!");
          setVerificationCode("");
          setNewPassword("");
          toast(
            <div>
              Password reset! <br />
              Page will reload momentarily.
            </div>
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        },
        onFailure(err) {
          console.log("Password not confirmed!");
        },
      });
      // ---
    },
    [selectedOption, username, verificationCode, newPassword]
  );

  return (
    <form onSubmit={handleForgotPassword}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Verification Code"
            fullWidth
            value={verificationCode}
            onChange={handleVerificationCodeChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            fullWidth
            onClick={handleSendCode}
          >
            Resend Code
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Reset Password
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResetPasswordForm;
