import React, { useCallback, useMemo, useState } from "react";
//import { Link as RouterLink, Redirect } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { IconButton } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

// ES Modules, e.g. transpiling with Babel
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import * as AWS from "aws-sdk/global";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { login } from "../../actions/authActions";
import ResetPasswordForm from "./ResetPasswordForm";

const AWSRegion = "us-east-1";

// cognitoConfig
// - UserPoolId
// - ClientId
// - IdentityPoolId
const cognitoConfig = {
  accinv: [
    "us-east-1_jACxkXaPO",
    "2hp2i2bohkv6co9vglrtpg57f2",
    "us-east-1:d4ba6cbf-255b-4bc3-aa2b-57f4c2f70a9c",
  ],
  member: [
    "us-east-1_synT6l4pN",
    "50ulqrn72cpcrhqcmum0qgc2t",
    "us-east-1:36a97af0-6654-4c6b-9e63-2303442df06a",
  ],
};

const LoginSignupPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const qs = search
    .slice(1)
    .split("&")
    .reduce((acc, val) => {
      const [key, value] = val.split("=");
      return Object.assign({ [key]: value }, acc);
    }, {});
  console.log(qs);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [EVCode, setEVCode] = useState("");
  const [password, setPassword] = useState("");
  const [selectedOption, setSelectedOption] = useState("accinv");
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [address, setAddress] = useState("");

  console.log({
    username,
    email,
    password,
    name,
    phoneNumber,
    birthdate,
    address,
  });

  const finalStep = 6;

  const prevVisible = useMemo(() => {
    switch (step) {
      case 0:
      case 5:
        /*
      case 3:
      case 6:
      */
        return false;
      default:
        return true;
    }
  }, [step]);

  const nextVisible = useMemo(() => {
    switch (step) {
      case 0:
      case 4:
      case 5:
        return false;
      default:
        return true;
    }
  }, [step]);

  const prevStep = useCallback(() => {
    switch (step) {
      /*
      case 1:
      case 2:
        setStep(0);
        break;
      */
      default:
        setStep(step > 0 ? step - 1 : 0);
        break;
    }
  }, [step, selectedOption]);

  const nextStep = useCallback(() => {
    switch (step) {
      default:
        setStep(step < finalStep ? step + 1 : finalStep);
        break;
    }
  }, [step, selectedOption]);

  const handleLogin = useCallback(
    (e) => {
      e.preventDefault();
      // Perform login logic here, e.g., making an API call

      const [UserPoolId, ClientId, IdentityPoolId] =
        cognitoConfig[selectedOption];

      var authenticationData = {
        Username: username,
        Password: password,
      };
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      var poolData = {
        UserPoolId, // Your user pool id here
        ClientId, // Your client id here
      };
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      var userData = {
        Username: username,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          var accessToken = result.getAccessToken().getJwtToken();

          //POTENTIAL: Region needs to be set if not already set previously elsewhere.
          AWS.config.region = AWSRegion;

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId, // your identity pool id here
            Logins: {
              // Change the key below according to the specific region your user pool is in.
              [`cognito-idp.${AWSRegion}.amazonaws.com/${UserPoolId}`]: result
                .getIdToken()
                .getJwtToken(),
            },
          });
          //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
          AWS.config.credentials.refresh((error) => {
            if (error) {
              console.error(error);
            } else {
              // Instantiate aws sdk service objects now that the credentials have been updated.
              // example: var s3 = new AWS.S3();
              console.log("Successfully logged!");
            }
          });
          dispatch(login(cognitoUser));
          const navigateTo = "redirect_to" in qs ? qs.redirect_to : "/";
          if (selectedOption === "accinv") {
            navigate(navigateTo);
          } else {
            navigate(navigateTo);
          }
        },
        onFailure: function (err) {
          alert(err.message || JSON.stringify(err));
        },
      });
    },
    [selectedOption, username, password]
  );

  const handleSendCode = useCallback(
    (e) => {
      e.preventDefault();
      if (!selectedOption || !username) return;
      // ---
      const [UserPoolId, ClientId, IdentityPoolId] =
        cognitoConfig[selectedOption];
      var poolData = {
        UserPoolId, // Your user pool id here
        ClientId, // Your client id here
      };
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      var userData = {
        Username: username,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      // ---
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
          console.log("CodeDeliveryData from forgotPassword: " + data);
        },
        onFailure: function (err) {
          alert(err.message || JSON.stringify(err));
        },
        //Optional automatic callback
        inputVerificationCode: function (data) {
          console.log("Code sent to: " + data);
        },
      });
      // ---
    },
    [selectedOption, username]
  );

  const handleVerify = (e) => {
    const [UserPoolId, ClientId, IdentityPoolId] =
      cognitoConfig[selectedOption];
    var poolData = {
      UserPoolId, // Your user pool id here
      ClientId, // Your client id here
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    var userData = {
      Username: username,
      Pool: userPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(EVCode, true, function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      console.log("call result: " + result);
      nextStep();
    });
  };

  const handleSignup = useCallback(
    (e) => {
      e.preventDefault();
      // Perform signup logic here, e.g., making an API call

      const [UserPoolId, ClientId, IdentityPoolId] =
        cognitoConfig[selectedOption];

      var poolData = {
        UserPoolId, // Your user pool id here
        ClientId, // Your client id here
      };

      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

      var attributeList = [];

      var dataName = {
        Name: "name",
        Value: name,
      };

      var dataEmail = {
        Name: "email",
        Value: email,
      };

      var dataPhoneNumber = {
        Name: "phone_number",
        Value: phoneNumber,
      };

      var dataBirthdate = {
        Name: "birthdate",
        Value: birthdate,
      };

      var dataAddress = {
        Name: "address",
        Value: address,
      };

      var attributeName = new AmazonCognitoIdentity.CognitoUserAttribute(
        dataName
      );

      var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
        dataEmail
      );
      var attributePhoneNumber = new AmazonCognitoIdentity.CognitoUserAttribute(
        dataPhoneNumber
      );

      var attributeBirthdate = new AmazonCognitoIdentity.CognitoUserAttribute(
        dataBirthdate
      );

      var attributeAddress = new AmazonCognitoIdentity.CognitoUserAttribute(
        dataAddress
      );

      attributeList.push(attributeName);
      attributeList.push(attributeEmail);
      attributeList.push(attributePhoneNumber);
      attributeList.push(attributeBirthdate);
      attributeList.push(attributeAddress);

      userPool.signUp(
        username,
        password,
        attributeList,
        null,
        function (err, result) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }
          var cognitoUser = result.user;
          console.log("user name is " + cognitoUser.getUsername());
        }
      );
    },
    [selectedOption, username, email, password, phoneNumber, birthdate, address]
  );

  /*
  if (isLoggedin) {
    // Redirect to the home page if the user is logged in
    return <Redirect to="/" />;
  }
  */

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: { xs: 8, sm: 16 },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 5,
        }}
      >
        <Paper
          sx={{
            minHeight: "400px",
            pt: 5,
            pb: 5,
            pl: !prevVisible ? "51px" : "0px",
            pr: !nextVisible ? "51px" : "0px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {prevVisible && (
            <IconButton onClick={prevStep}>
              <ArrowLeft fontSize="large" />
            </IconButton>
          )}
          {step === 0 && (
            <Box>
              <Typography component="h1" variant="h5">
                Login / Signup
              </Typography>
              <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Username"
                      name="username"
                      //autoComplete="usename"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      //autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      //autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <FormControl component="fieldset" sx={{ mt: 3 }}>
                      <RadioGroup
                        row
                        aria-label="options"
                        name="options"
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <FormControlLabel
                          value="accinv"
                          control={<Radio />}
                          label="Accredited Investor"
                        />
                        <FormControlLabel
                          value="member"
                          control={<Radio />}
                          label="Member"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Login
                    </Button>
                    <Button fullWidth variant="outlined" onClick={nextStep}>
                      Signup
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={(e) => {
                        setStep(5);
                        handleSendCode(e);
                      }}
                    >
                      Forgot Password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {step === 1 && (
            <Box>
              <Typography component="h1" variant="h5">
                Personal Information
              </Typography>
              <Box
                component="form"
                onSubmit={handleLogin}
                sx={{ mt: 3, width: { xs: "280px", sm: "500px" } }}
              >
                <Grid
                  container
                  sx={{
                    mt: 2,
                    border: "1px dashed grey",
                    borderRadius: 2,
                    gap: 1,
                    p: 1,
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="phone_number"
                      label="Phone Number"
                      name="phone_number"
                      defaultValue={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="birthdate"
                      label="Birthdate"
                      name="birthdate"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={birthdate}
                      onChange={(e, nv) => {
                        setBirthdate(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="address"
                      label="Address"
                      name="address"
                      multiline
                      rows={4}
                      defaultValue={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={(e) => {
                    nextStep();
                  }}
                  sx={{ mt: 3 }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          )}
          {step === 2 && (
            <Box>
              <Typography component="h1" variant="h5">
                Confirmation
              </Typography>
              <Box
                component="form"
                onSubmit={handleLogin}
                sx={{ mt: 3, width: { xs: "280px", sm: "500px" } }}
              >
                <Grid
                  container
                  sx={{
                    mt: 2,
                    border: "1px dashed grey",
                    borderRadius: 2,
                    gap: 1,
                    p: 1,
                  }}
                >
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Typography>Name: {name}</Typography>
                      <Typography>Email: {email}</Typography>
                      <Typography>Phone Number: {phoneNumber}</Typography>
                      <Typography>Birthdate: {birthdate.toString()}</Typography>
                      <Typography>Address: {address}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={(e) => {
                    nextStep();
                    handleSignup(e);
                  }}
                  sx={{ mt: 3 }}
                >
                  Signup
                </Button>
              </Box>
            </Box>
          )}
          {step === 3 && (
            <Box>
              <Typography component="h1" variant="h5">
                Verify Email
              </Typography>
              <Box
                component="form"
                onSubmit={handleLogin}
                sx={{ mt: 3, width: { xs: "280px", sm: "500px" } }}
              >
                <Grid
                  container
                  sx={{
                    mt: 2,
                    border: "1px dashed grey",
                    borderRadius: 2,
                    gap: 1,
                    p: 1,
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="evcode"
                      label="Email Verification Code"
                      name="evcode"
                      onChange={(e) => setEVCode(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleVerify}
                  sx={{ mt: 3 }}
                >
                  Verify
                </Button>
              </Box>
            </Box>
          )}
          {step === 4 && (
            <Box>
              <Typography component="h1" variant="h5">
                Account Verified!
              </Typography>
              <img
                src="/assets/clap.gif"
                align="left"
                style={{ width: "100%", marginTop: "1em" }}
              />
              <Box
                component="form"
                onSubmit={handleLogin}
                sx={{ mt: 3, width: { xs: "280px", sm: "500px" } }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    navigate("/");
                  }}
                  sx={{ mt: 3 }}
                >
                  Go to Dashboard
                </Button>
              </Box>
            </Box>
          )}
          {step === 5 && (
            <Box>
              <ResetPasswordForm
                username={username}
                selectedOption={selectedOption}
              />
            </Box>
          )}

          {nextVisible && (
            <IconButton onClick={nextStep}>
              <ArrowRight fontSize="large" />
            </IconButton>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default LoginSignupPage;
