import React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    minWidth: 433,
  },
  emptyBoxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  emptyBox: {
    marginBottom: theme.spacing(5),
  },
  viewMarketplaceButton: {
    marginTop: theme.spacing(2),
  },
}));

const AssetTable = ({ assets, onSort }) => {
  const classes = useStyles();

  const handleSort = (columnName) => {
    // Implement your sorting logic here
    onSort(columnName);
  };

  const renderTableContent = () => {
    if (assets.length === 0) {
      return (
        <Box className={classes.emptyBoxContainer}>
          <img src="/assets/empty-box.png" className={classes.emptyBox} />
          {/*<Typography variant="subtitle1">No assets yet</Typography>*/}
          <Link to="/marketplace" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.viewMarketplaceButton}
            >
              View Marketplace
            </Button>
          </Link>
        </Box>
      );
    } else {
      return (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort("investment")}>
                  Investment
                </TableCell>
                <TableCell onClick={() => handleSort("balance")}>
                  Balance
                </TableCell>
                <TableCell onClick={() => handleSort("distribution")}>
                  Distribution
                </TableCell>
                <TableCell onClick={() => handleSort("ownership")}>
                  Ownership
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assets.map((asset, index) => (
                <TableRow key={index}>
                  <TableCell>{asset.investment}</TableCell>
                  <TableCell>{asset.balance}</TableCell>
                  <TableCell>{asset.distribution}</TableCell>
                  <TableCell>{asset.ownership}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

  return renderTableContent();
};

export default AssetTable;
