import React, { useCallback, useEffect, useState } from "react";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  GetObjectCommand,
  ListObjectsCommand,
  ListObjectsCommandOutput,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import {
  fromCognitoIdentity,
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-providers";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import TransactionsTable from "../TransactionsTable";

const cognitoConfig = {
  demo2: [
    "us-east-1_ozU2lm0TM",
    "7cp69su09uuf46spr6sr7ep72d",
    "us-east-1:76a9861f-69a4-4e6a-befb-20c1a9300879",
  ],
};

const bucketName = "smartblock-demo2";

const [UserPoolId, ClientId, IdentityPoolId] = cognitoConfig["demo2"];

const AWSRegion = "us-east-1";

const UserDocuments = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const user = useSelector((state) => state.user);

  const [documents, setDocuments] = useState(["Contents"]);
  const [myDocuments, setMyDocuments] = useState(["Contents"]);

  console.log({ documents, myDocuments });

  const [cognitoUser, setCognitoUser] = useState(null);

  const [logins, setLogins] = useState(null);
  const [identityId, setIdentityId] = useState("");

  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState("");
  const [contentType, setContentType] = useState("");

  const listDocuments = useCallback(() => {
    if (!user) return;
    try {
      setLoading(true);
      const logins = {
        [`cognito-idp.${AWSRegion}.amazonaws.com/${user.pool.userPoolId}`]:
          user.signInUserSession.idToken.jwtToken,
      };
      const identityId = `${AWSRegion}/${user.signInUserSession.idToken.payload.sub}`;
      setIdentityId(identityId);
      const client = new S3Client({
        region: AWSRegion,
        credentials: fromCognitoIdentityPool({
          clientConfig: { region: "us-east-1" },
          identityPoolId: IdentityPoolId,
          logins,
        }),
      });
      [
        {
          Prefix: "shared",
          setValues: setDocuments,
        },
        {
          Prefix: identityId,
          setValues: setMyDocuments,
        },
      ].forEach((el) => {
        const command = new ListObjectsCommand({
          Bucket: bucketName,
          Prefix: el.Prefix,
        });
        client.send(command).then(({ Contents }) => {
          el.setValues(
            (Contents || []).filter((obj) => !obj.Key.endsWith("/"))
          );
        });
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    listDocuments();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  const transactionsData = [
    {
      date: "2023-07-25",
      investment: "ABC Corp",
      type: "Buy",
      distributionMemo: "Dividend",
      amount: 5000,
    },
    {
      date: "2023-07-26",
      investment: "XYZ Inc",
      type: "Sell",
      distributionMemo: "Interest",
      amount: 2500,
    },
    {
      date: "2023-07-27",
      investment: "DEF Ltd",
      type: "Buy",
      distributionMemo: "Bonus",
      amount: 3000,
    },
    {
      date: "2023-07-28",
      investment: "ABC Corp",
      type: "Sell",
      distributionMemo: "Dividend",
      amount: 2000,
    },
    {
      date: "2023-07-29",
      investment: "XYZ Inc",
      type: "Buy",
      distributionMemo: "Interest",
      amount: 4000,
    },
    {
      date: "2023-07-25",
      investment: "ABC Corp",
      type: "Buy",
      distributionMemo: "Dividend",
      amount: 5000,
    },
    {
      date: "2023-07-26",
      investment: "XYZ Inc",
      type: "Sell",
      distributionMemo: "Interest",
      amount: 2500,
    },
    {
      date: "2023-07-27",
      investment: "DEF Ltd",
      type: "Buy",
      distributionMemo: "Bonus",
      amount: 3000,
    },
    {
      date: "2023-07-28",
      investment: "ABC Corp",
      type: "Sell",
      distributionMemo: "Dividend",
      amount: 2000,
    },
    {
      date: "2023-07-29",
      investment: "XYZ Inc",
      type: "Buy",
      distributionMemo: "Interest",
      amount: 4000,
    },
    {
      date: "2023-07-25",
      investment: "ABC Corp",
      type: "Buy",
      distributionMemo: "Dividend",
      amount: 5000,
    },
    {
      date: "2023-07-26",
      investment: "XYZ Inc",
      type: "Sell",
      distributionMemo: "Interest",
      amount: 2500,
    },
    {
      date: "2023-07-27",
      investment: "DEF Ltd",
      type: "Buy",
      distributionMemo: "Bonus",
      amount: 3000,
    },
    {
      date: "2023-07-28",
      investment: "ABC Corp",
      type: "Sell",
      distributionMemo: "Dividend",
      amount: 2000,
    },
    {
      date: "2023-07-29",
      investment: "XYZ Inc",
      type: "Buy",
      distributionMemo: "Interest",
      amount: 4000,
    },
    {
      date: "2023-07-25",
      investment: "ABC Corp",
      type: "Buy",
      distributionMemo: "Dividend",
      amount: 5000,
    },
    {
      date: "2023-07-26",
      investment: "XYZ Inc",
      type: "Sell",
      distributionMemo: "Interest",
      amount: 2500,
    },
    {
      date: "2023-07-27",
      investment: "DEF Ltd",
      type: "Buy",
      distributionMemo: "Bonus",
      amount: 3000,
    },
    {
      date: "2023-07-28",
      investment: "ABC Corp",
      type: "Sell",
      distributionMemo: "Dividend",
      amount: 2000,
    },
    {
      date: "2023-07-29",
      investment: "XYZ Inc",
      type: "Buy",
      distributionMemo: "Interest",
      amount: 4000,
    },
    // Add more transaction objects as needed...
  ];

  return (
    <Container sx={{ pt: 5 }}>
      <Stack gap={5}>
        <Box>
          <h2>Transactions</h2>
          <TransactionsTable transactions={transactionsData} />
        </Box>
      </Stack>
    </Container>
  );
};

export default UserDocuments;
