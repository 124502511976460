import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
  galleryContainer: {
    padding: theme.spacing(2),
  },
  photoContainer: {
    position: 'relative',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: 'auto',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
  },
  caption: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
    padding: theme.spacing(1),
    fontSize: 16,
  },
}));

const PhotoGallery = () => {
  const classes = useStyles();

  const propertyData = [
    { imageSrc: 'https://picsum.photos/200/300', caption: 'Property 1' },
    { imageSrc: 'https://picsum.photos/200/300', caption: 'Property 1' },
    { imageSrc: 'https://picsum.photos/200/300', caption: 'Property 1' },
    { imageSrc: 'https://picsum.photos/200/300', caption: 'Property 1' },
    { imageSrc: 'https://picsum.photos/200/300', caption: 'Property 1' },
    // Add more property data with image URLs and captions as needed
  ];

  return (
    <div className={classes.galleryContainer}>
      <Grid container spacing={2}>
        {propertyData.map((property, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} className={classes.photoContainer}>
              <img src={property.imageSrc} alt={property.caption} />
              <div className={classes.caption}>{property.caption}</div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PhotoGallery;
