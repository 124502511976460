import React from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const GeneralSettings = () => {
  const verifications = [
    {
      name: "Know Your Customer (KYC)",
      description: "KYC is required for investors.",
      buttonText: "Verify Info",
      incompleteText: "Investor KYC is required before investing.",
    },
    {
      name: "Self Accreditation",
      description:
        "Allows you to access investments that require self-accreditation.",
      buttonText: "Complete Now",
      incompleteText: "Questionnaire not completed",
    },
    {
      name: "Certified accreditation",
      description: "Allows you to access investments that require certified accreditation.",
      buttonText: "Upload Accreditation",
      incompleteText: "Investor KYC is required before investing.",
    },
  ];
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Verification
      </Typography>
      {/* Add your settings content here */}
      <Stack gap={5}>
        {verifications.map((el) => (
          <Paper sx={{ p: 2 }}>
            <Typography variant="h5">{el.name}</Typography>
            <Typography variant="body">{el.description}</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <ErrorOutlineIcon color="warning" />
              <Typography variant="body2" sx={{ color: "#ed6c02", ml: 1 }}>
                {el.incompleteText}
              </Typography>
              <Button size="small" variant="text">
                {el.buttonText}
              </Button>
            </Box>
          </Paper>
        ))}
      </Stack>
    </div>
  );
};

export default GeneralSettings;
