import React from "react";
import { Typography } from "@mui/material";
import UserProfileTable from "../../components/UserProfileTable";

const GeneralSettings = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      {/* Add your settings content here */}
      <UserProfileTable />
    </div>
  );
};

export default GeneralSettings;
