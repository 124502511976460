// withBalance.js (HOC)
import React, { useEffect, useState } from "react";
import ARC200Service from "../services/ARC200Service";
import { makeStdLib } from "../utils/reach";

const stdlib = makeStdLib();
const fc = stdlib.formatCurrency;

const withTokenMetadata = (WrappedComponent) => {
  return (props) => {
    const { appId, backendId } = props;
    const [token, setToken] = useState(null);

    useEffect(() => {
      // Fetch the balance and update the state
      ARC200Service.getTokenMetadata(appId, backendId).then((token) => {
        setToken(token);
      });
    }, [appId, backendId]);

    // If the balance is still null (fetching in progress), you can show a loader or placeholder
    //if (balance === null) {
    //  return <div>Loading...</div>;
    //}

    // Pass the balance as a prop to the WrappedComponent
    return (
      <WrappedComponent {...props} loading={token === null} token={token} />
    );
  };
};

export default withTokenMetadata;
