import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import Title from "./Title";
import { useWallet } from "@txnlab/use-wallet";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EjectIcon from "@mui/icons-material/Eject";
import { useSelector } from "react-redux";

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";

const cognitoConfig = {
  demo2: [
    "us-east-1_ozU2lm0TM",
    "7cp69su09uuf46spr6sr7ep72d",
    "us-east-1:76a9861f-69a4-4e6a-befb-20c1a9300879",
  ],
};

const AWSRegion = "us-east-1";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  rowContainer: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontWeight: "bold",
  },
  usdText: {
    fontSize: "1rem",
  },
  paperContainer: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
  },
  connectWalletLabel: {
    marginTop: theme.spacing(2),
  },
  // Add margin between the 1st and 2nd Grid container
  gridContainerMargin: {
    marginTop: theme.spacing(2),
  },
}));

const PortfolioDisplay = () => {
  const { providers, activeAccount } = useWallet();

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const user = useSelector((state) => state.user);

  const [wallet, setWallet] = useState(null);

  console.log({ wallet });
  console.log({ providers, activeAccount });
  console.log({ isLoggedIn, user });

  useEffect(() => {
    const {
      pool: { clientId: ClientId, userPoolId: UserPoolId },
    } = user;
    const poolData = {
      UserPoolId,
      ClientId,
      //Storage: window.localStorage,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        console.log("session validity: " + session.isValid());
        const IdentityPoolId = `${AWSRegion}/${user.signInUserSession.idToken.payload.sub}`;
        AWS.config.region = AWSRegion;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId,
          Logins: {
            [`cognito-idp.${AWSRegion}.amazonaws.com/${user.pool.userPoolId}`]:
              session.getIdToken().getJwtToken(),
          },
        });
        // Instantiate aws sdk service objects now that the credentials have been updated.
        if (!wallet) {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              alert(err.message || JSON.stringify(err));
              return;
            }
            for (let i = 0; i < result.length; i++) {
              const attributeName = result[i].getName();
              console.log(attributeName);
              switch (attributeName) {
                case "custom:algo_addr":
                  setWallet(result[i].getValue());
                  break;
                default:
                  continue;
              }
            }
          });
        }

        if (!activeAccount?.address !== wallet) return;

        const customAttribute = "custom:algo_addr";
        var attributeList = [];
        var attribute = {
          Name: customAttribute,
          Value: activeAccount?.address,
        };
        var attribute = new AmazonCognitoIdentity.CognitoUserAttribute(
          attribute
        );
        attributeList.push(attribute);
        cognitoUser.updateAttributes(attributeList, function (err, result) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }
          console.log("call result: " + result);
          setWallet(activeAccount?.address);
        });
      });
    }
  }, [activeAccount]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Title>Portfolio</Title>
      {/* Real Estate Holdings */}
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.rowContainer}
      >
        <Grid item xs={12}>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            Real Estate Holdings
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paperContainer}>
            <div className={classes.usdText}>$0</div>
          </Paper>
        </Grid>
      </Grid>
      {/* Add margin between the 1st and 2nd Grid container */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={`${classes.rowContainer} ${classes.gridContainerMargin}`}
      >
        <Grid item xs={4}>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            Assets
          </Typography>
          <Paper elevation={3} className={classes.paperContainer}>
            <div className={classes.usdText}>$0</div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            Shares
          </Typography>
          <Paper elevation={3} className={classes.paperContainer}>
            <div className={classes.usdText}>$0</div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            Distributions
          </Typography>
          <Paper elevation={3} className={classes.paperContainer}>
            <div className={classes.usdText}>$0</div>
          </Paper>
        </Grid>
      </Grid>
      {/* Connect Digital Wallet Button */}
      {activeAccount ? (
        <Stack gap={1}>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            Digital Wallet
          </Typography>
          <Paper elevation={3} className={classes.paperContainer}>
            <Stack
              direction="row"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {providers
                ?.filter((el) =>
                  [activeAccount.providerId].includes(el.metadata.id)
                )
                ?.map((provider) => (
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        alignItems: "center",
                        gap: "1em",
                        marginRight: "1rem",
                      },
                    }}
                  >
                    <img
                      style={{ height: "30px" }}
                      src={provider.metadata.icon}
                    />
                  </Box>
                ))}
              <Box
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: 100,
                }}
              >
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                  {activeAccount.address}
                </Typography>
              </Box>
              <IconButton>
                <ContentCopyIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  activeAccount &&
                    providers &&
                    providers
                      .find((p) => p.metadata.id === activeAccount?.providerId)
                      .disconnect();
                  //window.location.reload();
                }}
              >
                <EjectIcon />
              </IconButton>
            </Stack>
          </Paper>
        </Stack>
      ) : wallet ? (
        <Stack gap={1}>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            Connect Digital Wallet
          </Typography>
          <Paper elevation={3} className={classes.paperContainer}>
            <Stack
              direction="row"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {providers
                ?.filter((el) => ["pera", "defly"].includes(el.metadata.id))
                ?.map((provider) => (
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        alignItems: "center",
                        gap: "1em",
                      },
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        provider.connect().then(() => {
                          //window.location.reload();
                        });
                      }}
                    >
                      <img
                        style={{
                          height: "30px",
                          filter: "grayscale(1)",
                          cursor: "pointer",
                        }}
                        src={provider?.metadata?.icon}
                      />
                    </IconButton>
                  </Box>
                ))}
              <Box
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: 100,
                }}
              >
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                  {wallet}
                </Typography>
              </Box>
              <IconButton>
                <ContentCopyIcon />
              </IconButton>
            </Stack>
          </Paper>
        </Stack>
      ) : (
        <Stack gap={1}>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            Connect Digital Wallet
          </Typography>
          <Stack direction="row">
            {providers
              ?.filter((el) => ["pera", "defly"].includes(el.metadata.id))
              ?.map((provider) => (
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      alignItems: "center",
                      gap: "1em",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    style={{ color: "white" }}
                    onClick={() => {
                      provider.connect().then(() => {
                        window.location.reload();
                      });
                    }}
                  >
                    <img
                      style={{ height: "30px", filter: "grayscale(1)" }}
                      src={provider.metadata.icon}
                    />
                  </Button>
                </Box>
              ))}
          </Stack>
        </Stack>
      )}
    </div>
  );
};

export default PortfolioDisplay;
