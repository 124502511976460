import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const DocumentTable = ({ documents, prefix }) => {
  const styles = {
    header: {
      fontWeight: "bold",
    },
    firstCell: {
      width: "70%",
    },
  };
  return documents.length > 0 ? (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ ...styles.firstCell, ...styles.header }}>
              Document
            </TableCell>
            <TableCell style={{ ...styles.header }}>Size</TableCell>
            <TableCell style={{ ...styles.header }}>LastModified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <TableRow key={document.Key}>
              <TableCell style={{ ...styles.firstCell }}>
                {document?.Key?.replace(prefix, "")}&nbsp;
                <a href="#">View</a>
              </TableCell>
              <TableCell>{document.Size}</TableCell>
              <TableCell>
                {document?.LastModified?.toISOString() ?? ""}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    "No document yet"
  );
};

export default DocumentTable;
