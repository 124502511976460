import React, { useCallback, useEffect, useState } from "react";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  GetObjectCommand,
  ListObjectsCommand,
  ListObjectsCommandOutput,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import {
  fromCognitoIdentity,
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-providers";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import DocumentTable from "./DocumentTable";

import moment from "moment";

import { logout } from "../../actions/authActions";

const cognitoConfig = {
  demo2: [
    "us-east-1_ozU2lm0TM",
    "7cp69su09uuf46spr6sr7ep72d",
    "us-east-1:76a9861f-69a4-4e6a-befb-20c1a9300879",
  ],
};

const bucketName = "smartblock-demo2";

const [UserPoolId, ClientId, IdentityPoolId] = cognitoConfig["demo2"];

const AWSRegion = "us-east-1";

const UserDocuments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const user = useSelector((state) => state.user);

  console.log({ isLoggedIn, user });

  const [documents, setDocuments] = useState(["Contents"]);
  const [myDocuments, setMyDocuments] = useState(["Contents"]);

  console.log({ documents, myDocuments });

  const [cognitoUser, setCognitoUser] = useState(null);

  const [logins, setLogins] = useState(null);
  const [identityId, setIdentityId] = useState("");

  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState("");
  const [contentType, setContentType] = useState("");

  const handleSignout = () => {
    const {
      pool: { clientId: ClientId, userPoolId: UserPoolId },
    } = user;
    const poolData = {
      UserPoolId, // Your user pool id here
      ClientId, // Your client id here
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.signOut(() => {
      dispatch(logout());
      navigate("/");
    });
  };

  useEffect(() => {
    if (!user) return;
    if (
      user.signInUserSession.accessToken.payload.exp <
      Math.floor(new Date() / 1000)
    ) {
      handleSignout();
    }
  }, [user]);

  const listDocuments = useCallback(() => {
    if (!user) return;
    try {
      setLoading(true);
      const logins = {
        [`cognito-idp.${AWSRegion}.amazonaws.com/${user.pool.userPoolId}`]:
          user.signInUserSession.idToken.jwtToken,
      };
      const identityId = `${AWSRegion}/${user.signInUserSession.idToken.payload.sub}`;
      setIdentityId(identityId);
      const client = new S3Client({
        region: AWSRegion,
        credentials: fromCognitoIdentityPool({
          clientConfig: { region: "us-east-1" },
          identityPoolId: IdentityPoolId,
          logins,
        }),
      });
      [
        {
          Prefix: "shared",
          setValues: setDocuments,
        },
        {
          Prefix: identityId,
          setValues: setMyDocuments,
        },
      ].forEach((el) => {
        const command = new ListObjectsCommand({
          Bucket: bucketName,
          Prefix: el.Prefix,
        });
        client.send(command).then(({ Contents }) => {
          el.setValues(
            (Contents || []).filter((obj) => !obj.Key.endsWith("/"))
          );
        });
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    listDocuments();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  return (
    <Container sx={{ pt: 5 }}>
      <Stack gap={5}>
        <Box>
          <h2>Shared Documents</h2>
          <DocumentTable documents={documents} prefix="shared/" />
        </Box>
        <Box>
          <h2>My Documents</h2>
          <DocumentTable documents={myDocuments} prefix={`${identityId}/`} />
        </Box>
      </Stack>
    </Container>
  );
};

export default UserDocuments;
