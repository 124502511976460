import React, { useRef, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Container,
} from "@mui/material";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const captchaRef = useRef(null);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = captchaRef.current.getValue();
    if (token === "") {
      return;
    }
    captchaRef.current.reset();

    // Handle form submission logic here
    console.log("Name:", name);
    console.log("Phone Number:", phoneNumber);
    console.log("Email:", email);
    console.log("Message:", message);

    try {
      const data = {
        name,
        phone: phoneNumber,
        email,
        desc: message,
      };
      await axios.post(
        "https://7oj4us31uc.execute-api.us-east-1.amazonaws.com/test/test",
        data
      );
      toast(<div>Thank you for your feedback!</div>);
      // Reset form fields
      setName("");
      setPhoneNumber("");
      setEmail("");
      setMessage("");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container sx={{ mt: { xs: 2, sm: 5 } }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "2rem" }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Contact Us
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={handleNameChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={message}
                    onChange={handleMessageChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={captchaRef}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactPage;
