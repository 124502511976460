import SignInSide from "../../components/SignInSide";
import { useSelector } from "react-redux";
import Dashboard from "../../components/Dashboard";

const Page = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  return isLoggedIn ? <Dashboard /> : <SignInSide />;
};

export default Page;
