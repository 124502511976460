import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./listItems";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";
import Copyright from "../PoweredBy";
import PortfolioDisplay from "./PortfolioDisplay";
import Title from "./Title";
import USDCBalance from "./UDCBalance";
import AssetTable from "./AssetTable";
import { useWallet } from "@txnlab/use-wallet";
import Bridge from "./Bridge";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
  const { activeAccount } = useWallet();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Portfolio */}
              <Grid item xs={12} md={4} lg={4}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginBottom: "24px",
                    }}
                  >
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        background: "#f0f0f0",
                      }}
                    >
                      <PortfolioDisplay />
                    </Paper>
                  </Grid>
                  {activeAccount && (
                    <>
                      <Grid item xs={12} sx={{ marginBottom: "24px" }}>
                        <Paper
                          sx={{
                            p: 0,
                            display: "flex",
                            flexDirection: "column",
                            background: "#f0f0f0",
                          }}
                        >
                          <USDCBalance
                            appId={264909298}
                            backendId={"nnt"}
                            address={activeAccount?.address}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            p: 0,
                            display: "flex",
                            flexDirection: "column",
                            background: "#f0f0f0",
                          }}
                        >
                          <Bridge
                            address={activeAccount?.address}
                            appId={264909298}
                            assetId={10458941}
                            backendId={"nnt"}
                          />
                        </Paper>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              {/* Asset Table */}
              <Grid item xs={12} md={8} lg={8}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 641.25,
                    alignItems: "center",
                  }}
                >
                  <AssetTable assets={[]} />
                </Paper>
              </Grid>
              {/* Chart */}
              {/*<Grid item xs={12} md={8} lg={8}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 443.5,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid>
                */}
              {/* Recent Deposits */}
              {/*<Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 240,
                  }}
                >
                  <Deposits />
                </Paper>
                </Grid>*/}
              {/* Recent Orders */}
              {/*<Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Orders />
                </Paper>
              </Grid>*/}
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
