import React from "react";
import { Route, Routes } from "react-router-dom";
import { Grid, Paper, Container } from "@mui/material";
import Sidebar from "./Sidebar";
import GeneralSettings from "./GeneralSettings";
import VerificationSettings from "./VerificationSettings";
import ProfileSettings from "./ProfileSettings";

const SettingsPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Paper elevation={0} style={{ padding: "20px", minHeight: '50vh' }}>
            <Routes>
              <Route path="profile" element={<ProfileSettings />} />
              <Route path="verify" element={<VerificationSettings />} />
              <Route path="" element={<GeneralSettings />} />
            </Routes>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SettingsPage;
