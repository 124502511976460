import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Powered by&nbsp;
      <Link
        component={RouterLink} // Use the RouterLink component from react-router-dom
        to="https://smartblockisland.com/" // Replace with the actual link path
        style={{
          textDecoration: "none", // Remove underline
          color: "inherit", // Inherit text color from parent (no specific color)
        }}
      >
        SmartBlock🏝️
      </Link>{" "}
    </Typography>
  );
}

export default Copyright;
