// withBalance.js (HOC)
import React, { useEffect, useState } from "react";
import { makeStdLib } from "../utils/reach";

const stdlib = makeStdLib();
const fc = stdlib.formatCurrency;

const withBalance = (WrappedComponent) => {
  return ({ assetId, address, ...props }) => {
    const [balance, setBalance] = useState(null);
    useEffect(() => {
      // Fetch the balance and update the state
      stdlib
        .connectAccount({ addr: address })
        .then((acc) => stdlib.balanceOf(acc, assetId))
        .then(fc)
        .then(setBalance);
    }, [address, assetId]);

    // If the balance is still null (fetching in progress), you can show a loader or placeholder
    //if (balance === null) {
    //  return <div>Loading...</div>;
    //}

    // Pass the balance as a prop to the WrappedComponent
    return (
      <WrappedComponent
        {...props}
        loading={balance === null}
        balance={balance}
      />
    );
  };
};

export default withBalance;
