import { Route, Routes } from "react-router-dom";
import "./App.css";

import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import LoginSignupPage from "./pages/LoginSignupPage";
import Marketplace from "./pages/Marketplace";
import Asset from "./pages/Asset";
import Documents from "./pages/Documents";
import Profile from "./pages/Profile";
import Transactions from "./pages/Transactions";
import Settings from "./pages/Settings";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomAppBar from "./components/CustomAppBar";

import algosdk from "algosdk";

import {
  PROVIDER_ID,
  WalletProvider,
  useInitializeProviders,
} from "@txnlab/use-wallet";

import { DeflyWalletConnect } from "@blockshake/defly-connect";
import { PeraWalletConnect } from "@perawallet/connect";
import { DaffiWalletConnect } from "@daffiwallet/connect";
import { WalletConnectModalSign } from "@walletconnect/modal-sign-html";

const App = () => {
  const providers = useInitializeProviders({
    providers: [
      { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
      { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
      { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
      {
        // TODO here
        id: PROVIDER_ID.WALLETCONNECT,
        clientStatic: WalletConnectModalSign,
        clientOptions: {
          projectId: "1da7d79da1e1c620ac717a04f1bf2cb4",
          metadata: {
            name: "ARC200",
            description: "ARC200 Dapp",
            url: "https://temptemp3.github.io/arc200.algo.xyz/",
            icons: ["https://walletconnect.com/walletconnect-logo.png"],
          },
          modalOptions: {
            themeMode: "dark",
          },
        },
      },
      { id: PROVIDER_ID.EXODUS },
    ],
    nodeConfig: {
      network: "testnet",
      nodeServer: "https://testnet-api.algonode.cloud",
      nodeToken: "",
      nodePort: "443",
    },
    algosdkStatic: algosdk,
  });
  const state = JSON.parse(localStorage.getItem("state") || "{}");
  return (
    <WalletProvider value={providers}>
      <div className="App">
        <CustomAppBar />
        <Routes>
          <Route path="/asset/:appId" element={<Asset />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/login" element={<LoginSignupPage />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <ToastContainer />
      </div>
    </WalletProvider>
  );
};

export default App;
