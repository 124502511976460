import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";

//---
import * as icoBackend from "../../backend/ico/index.ico.mjs";
import * as nntBackend from "../../backend/arc200/index.nnt.mjs";
import * as arc200Backend from "../../backend/arc200/index.ARC200.mjs";
import { useWallet } from "@txnlab/use-wallet";
import { makeStdLib } from "../../utils/reach";
import { fromSome } from "../../utils/common";
import { Link } from "react-router-dom";

const stdlib = makeStdLib();
const { algosdk } = stdlib;
const bn = stdlib.bigNumberify;
const pc = stdlib.parseCurrency;
//---

const useStyles = makeStyles((theme) => ({
  card: {
    width: 300,
    height: 400,
    borderRadius: 0,
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  textOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    width: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    transition: "opacity 0.3s ease-in-out",
    opacity: 1,
    minHeight: 160,
  },
  details: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: theme.spacing(2),
    width: "100%",
    background: "rgba(0, 0, 0, 0.7)",
    color: "white",
    transition: "opacity 0.3s ease-in-out",
    opacity: 0,
  },
  showDetails: {
    opacity: 1,
  },
  viewDetailsBtn: {
    marginTop: "1rem",
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: 20,
    padding: "8px 20px",
    background: "rgba(0, 0, 0, 0.8)",
    color: "white",
    transition: "opacity .5s ease-in-out", // Increase the transition duration to 0.5s
    opacity: 0,
  },
  showViewDetailsBtn: {
    opacity: 1,
  },
}));

const AssetCard = ({
  title,
  content,
  imageUrl,
  assetType,
  annualReturn,
  cashOnCash,
  equityMultiple,
  projectValue,
  minInvestment,
  appId,
}) => {
  const classes = useStyles();
  const { activeAccount } = useWallet();
  const [isHovering, setIsHovering] = useState(false);
  const [reserve, setReserve] = useState(null);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const icoCtcInfo = 266874037; // ICO (v2)
  const tokACtcInfo = 264909298; // ARC200-nnt (USDC)
  const tokBCtcInfo = appId; // ARC200

  useEffect(() => {
    if (!activeAccount) return;
    (async () => {
      const icoCtcAddress = algosdk.getApplicationAddress(icoCtcInfo);
      const acc = await stdlib.connectAccount({ addr: activeAccount.address });
      const ctcICO = acc.contract(icoBackend, icoCtcInfo);
      ctcICO.v
        .state()
        .then(console.log)
    })();
  }, [activeAccount]);

  const handleBuy = useCallback(async () => {
    const amt = Number(window.prompt("Enter amount to buy:"));
    const icoCtcAddress = algosdk.getApplicationAddress(icoCtcInfo);
    const acc = await stdlib.connectAccount({ addr: activeAccount.address });
    const ctcICO = acc.contract(icoBackend, icoCtcInfo);
    const ctcTokA = acc.contract(nntBackend, tokACtcInfo);
    const ctcTokB = acc.contract(arc200Backend, appId);
    await ctcTokA.a.approve(
      algosdk.getApplicationAddress(icoCtcInfo),
      fromSome(await ctcTokA.v.totalSupply(), bn(0))
    );
    await ctcTokA.a.transfer(icoCtcAddress, 0);
    await ctcICO.a.Trader.swapAForB(pc(amt));
  }, [appId, activeAccount]);

  /*
  const handleClaim = useCallback(async () => {
    const acc = await stdlib.connectAccount({ addr: activeAccount.address });
    const ctcICO = acc.contract(icoBackend, icoCtcInfo);
    const ctcTokA = acc.contract(nntBackend, tokACtcInfo);
    const ctcTokB = acc.contract(arc200Backend, appId);
    const { A, B } = fromSome(
      await ctcICO.v.reserve(activeAccount.address),
      {}
    );
    await ctcTokA.a.transfer(activeAccount.address, 0);
    await ctcTokB.a.transfer(activeAccount.address, 0);
    await ctcICO.a.Trader.withdrawA(A);
    await ctcICO.a.Trader.withdrawB(B);
  }, [appId, activeAccount]);

  const handleClaim2 = useCallback(async () => {
    const acc = await stdlib.connectAccount({ addr: activeAccount.address });
    const ctcICO = acc.contract(icoBackend, icoCtcInfo);
    const ctcTokA = acc.contract(nntBackend, tokACtcInfo);
    const ctcTokB = acc.contract(arc200Backend, appId);
    await ctcTokA.a.transfer(activeAccount.address, 0);
    await ctcTokB.a.transfer(activeAccount.address, 0);
    await ctcICO.a.Provider.withdrawA(999999);
    await ctcICO.a.Provider.withdrawB(0);
  }, [appId, activeAccount]);
  */

  return (
    <Paper
      elevation={3}
      className={classes.card}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Image container */}
      <div className={classes.mediaContainer}>
        <CardMedia component="img" src={imageUrl} className={classes.media} />
        {/* Title and Content Overlay */}
        <div
          className={`${classes.textOverlay} ${
            isHovering ? classes.showDetails : ""
          }`}
        >
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1">{content}</Typography>
        </div>
        {/* Asset Details */}
        <div
          className={`${classes.details} ${
            isHovering ? classes.showDetails : ""
          }`}
        >
          <Typography variant="body2">
            <strong>Asset Type:</strong> {assetType}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Annual Return:</strong> {annualReturn}
              </Typography>
              <Typography variant="body2">
                <strong>Cash on Cash:</strong> {cashOnCash}
              </Typography>
              <Typography variant="body2">
                <strong>Equity Multiple:</strong> {equityMultiple}
              </Typography>
              <Typography variant="body2">
                <strong>Project Value:</strong> {projectValue}
              </Typography>
              <Typography variant="body2">
                <strong>Min. Investment:</strong> {minInvestment}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              <Link to={`/asset/${appId}`}>
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.viewDetailsBtn} ${
                    isHovering ? classes.showViewDetailsBtn : ""
                  }`}
                >
                  View Details
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </Paper>
  );
};

export default AssetCard;
